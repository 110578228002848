import React from "react";


const About = () => {
  

  return (
      <div className="about-container">
        <h1 className="about-heading">About Sculpture Africa:</h1>
        {/* Your existing content */}
        <p className="about-text">
          Sculpture Africa is a premier art company based in South Africa,
          dedicated to the creation and promotion of exquisite sculptures
          crafted from a variety of materials including metal, stone, and wood.
          With a passion for celebrating African culture and heritage, we blend
          traditional craftsmanship with contemporary artistic techniques to
          produce unique and captivating pieces that resonate with art
          enthusiasts worldwide.
        </p>
        <h1 className="about-heading">Our Mission:</h1>
        <div>
          <span>
            <h3>Mission and Values</h3>
            <p>
              At Sculpture Africa, our mission is to showcase the rich diversity
              and beauty of African artistry while supporting local artisans and
              communities. We strive to preserve and promote traditional
              sculpting methods while embracing innovation and creativity in our
              artistic endeavors. Through our sculptures, we aim to tell
              stories, evoke emotions, and inspire a deeper appreciation for the
              cultural heritage of Africa.
            </p>
          </span>
        </div>
        {/* Your existing content */}
        <p className="about-text">
          Sculpture Africa is committed to making a positive impact on the
          communities in which we operate. We actively collaborate with local
          artists, craftsmen, and organizations to provide training, employment,
          and support for aspiring talent. Through workshops, mentorship
          programs, and community initiatives, we seek to empower individuals
          and communities, fostering a sense of pride and ownership in their
          cultural heritage.
        </p>
        <div>
          <span>
            <h3>Artistic Excellence</h3>
            <p>
              We take pride in our commitment to artistic excellence, ensuring
              that each sculpture created at Sculpture Africa reflects the
              highest standards of craftsmanship and creativity. Our team of
              skilled artisans possesses a deep understanding of their craft,
              honed through years of experience and a deep connection to their
              cultural roots. From intricate metalwork to the smooth contours of
              stone and the warmth of wood, every piece is meticulously crafted
              to captivate and inspire.
            </p>
          </span>
        </div>
        <div>
          <span>
            <h3>Diverse Materials</h3>
            <p>
              One of the hallmarks of Sculpture Africa is our versatility in
              working with a wide range of materials. From the strength and
              resilience of metal to the timeless beauty of stone and the
              organic warmth of wood, we harness the unique qualities of each
              material to bring our artistic visions to life. Whether it's the
              sleek lines of a bronze sculpture, the intricate detailing of a
              granite carving, or the natural textures of a wooden masterpiece,
              our sculptures are a testament to the endless possibilities of
              artistic expression.
            </p>
          </span>
        </div>
        <h1 className="about-heading">Global Reach</h1>
        {/* Your existing content */}
        <p className="about-text">
          While our roots may lie in South Africa, Sculpture Africa has a global
          reach, with our sculptures finding homes in galleries, museums,
          private collections, and public spaces around the world. We are proud
          to share the beauty and diversity of African art with audiences far
          and wide, fostering cross-cultural exchange and appreciation. Whether
          it's a monumental sculpture gracing a city square or a delicate piece
          adorning a private residence, each artwork carries with it a piece of
          Africa's rich cultural heritage.
        </p>
        <div>
          <span>
            <h3>Cultural Inspiration</h3>
            <p>
              Crafted with precision and an eye for detail, Gothrone places a
              premium on quality materials and meticulous craftsmanship. Our
              commitment to excellence ensures that each piece not only stands
              out in terms of design but also stands the test of time.
            </p>
          </span>
        </div>

        <div>
          <span>
            <h3>Artistic Excellence</h3>
            <p>
              To learn more about Sculpture Africa and explore our collection of
              sculptures. Whether you're a collector, an art enthusiast, or
              simply someone who appreciates the beauty of African art, we
              invite you to experience the magic of Sculpture Africa.
            </p>
          </span>
        </div>
        
    </div>
  );
};

export default About;
