import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

const ForPost = () => {
  const { items: data, status } = useSelector((state) => state.postCollections);

  const extractFirstTwoParagraphs = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const paragraphs = tempDiv.querySelectorAll('p');
    return Array.from(paragraphs).slice(0, 2).map(p => p.outerHTML).join('');
  };

  return (
    <div className="article-container">
      {status === "success" ? (
        <>
          <h2>Blog Posts</h2>
          <div className="articles">
            {data &&
              data.map((postCollection) => (
                <div key={postCollection._id} className="article-overlay">
                  <h3>{postCollection.title}</h3>
                  <img
                    src={postCollection.image.url}
                    alt={postCollection.image}
                    className="articles-image"
                  />
                  <div className="article-details">
                    <div
                      className="article-content"
                      dangerouslySetInnerHTML={{ __html: extractFirstTwoParagraphs(postCollection.content) }}
                    />
                    <Link to={`/postCollection/${postCollection._id}`}>
                      read more ...
                    </Link>
                    <div>
                      <span>{moment(postCollection.date).format('MMMM Do YYYY')}</span>
                    </div>
                    <div>
                      <span>{postCollection.author}</span>
                    </div>
                    <button>Share</button>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : status === "pending" ? (
        <p>Loading...</p>
      ) : (
        <p>Unexpected error occurred...</p>
      )}
    </div>
  );
};

export default ForPost;
