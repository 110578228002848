import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../features/cartSlice";
import { Link } from "react-router-dom";

const ForWoods = () => {
  const { items: data, status } = useSelector((state) => state.woods);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddToCart = (wood) => {
    dispatch(addToCart(wood));
    navigate("/cart");
  };

  return (
    <div className="home-container">
      <div className="hero-image">
        <img src="./images/center.JPG" alt="Hero Image" />
        
      </div>
      {status === "success" ? (
        <>
          <h2>Wood Sculpture on the way 20-05-2024</h2>
         
            <div className="woods">
              {data &&
                data?.map((wood) => (
                  <div key={wood._id} className="product-overlay">
                    <Link to={`/wood/${wood._id}`}>
                      <img
                        src={wood.image.url}
                        alt={wood.name}
                        className="product-image"
                      />
                    </Link>
                    <div className="product-details">
                      <h3>{wood.name}</h3>
                      <div className="details">
                        <span>{wood.desc}</span>
                        <span className="price">R{wood.price}</span>
                      </div>
                      <button onClick={() => handleAddToCart(wood)}>
                        {" "}
                        Add to Cart{" "}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
         
        </>
      ) : status === "pending" ? (
        <p> Loading ... </p>
      ) : (
        <p>Unexpected error occured ... </p>
      )}

    </div>
  );
};

export default ForWoods;
