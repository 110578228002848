import { useState, useEffect } from "react";
import { setHeaders, url } from "../../features/api";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToCart } from "../../features/cartSlice";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wood = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [wood, setWood] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        const res = await axios.get(
          `${url}/woods/find/${params.id}`,
          setHeaders()
        );

        setWood(res.data);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  const handleAddToCart = (wood) => {
    dispatch(addToCart(wood));
    navigate("/cart");
  };

  return (
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="productDetails">
            <img src={wood.image?.url} alt="wood" />
            <h3>{wood.name}</h3>
            <p>
              <span>Type:</span>
              {wood.type}
            </p>
            <p>
              <span>Description:</span>
              {wood.desc}
            </p>
            <div>
              R{wood.price?.toLocaleString()}
            </div>
            <button
              className="product-add-to-cart"
              onClick={() => handleAddToCart(wood)}
            >
              Add To Cart
            </button>
            </div>
          </>
        )}
      </div>
  );
};

export default Wood;

