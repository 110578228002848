import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url, setHeaders } from "./api";
import { toast } from "react-toastify";

const initialState = {
  items: [],
  status: null,
  createStatus: null,
  deleteStatus: null,
  editStatus: null,
};

export const woodsFetch = createAsyncThunk(
    "woods/woodsFetch",
    async () => {
      try {
        const response = await axios.get(`${url}/woods`);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    }
  );

  export const woodsCreate = createAsyncThunk(
    "woods/woodsCreate",
    async (values) => {
      try {
        const response = await axios.post(
          `${url}/woods`,
          values,
          setHeaders()
        );
  
        return response.data;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data);
      }
    }
  );

  export const woodsEdit = createAsyncThunk(
    "woods/woodsEdit",
    async (values) => {
      try {
        const response = await axios.put(
          `${url}/woods/${values.wood._id}`,
          values,
          setHeaders()
        );
        return response.data;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data);
      }
    }
  );

  export const woodsDelete = createAsyncThunk(
    "woods/woodsDelete",
    async (id) => {
      try {
        const response = await axios.delete(
          `${url}/woods/${id}`, 
          setHeaders()
        );
  
        return response.data;
      } catch (error) {
        console.log(error);
        toast.error(error.response?.data);
      }
    }
  );
  

  const woodsSlice = createSlice({
    name: "woods",
    initialState,
    reducers: {},
    extraReducers: {
      [woodsFetch.pending]: (state, action) => {
        state.status = "pending";
      },
      [woodsFetch.fulfilled]: (state, action) => {
        state.items = action.payload;
        state.status = "success";
      },
      [woodsFetch.rejected]: (state, action) => {
        state.status = "rejected";
      },
      [woodsCreate.pending]: (state, action) => {
        state.createStatus = "pending";
      },
      [woodsCreate.fulfilled]: (state, action) => {
        state.items.push(action.payload);
        state.createStatus = "success";
        toast.success("wood Created");
      },
      [woodsCreate.rejected]: (state, action) => {
        state.createStatus = "rejected";
      },
      [woodsEdit.pending]: (state, action) => {
        state.editStatus = "pending";
      },
      [woodsEdit.fulfilled]: (state, action) => { 
        const updatedwoods = state.items.map((wood) =>
        wood._id === action.payload._id ? action.payload : wood
        );  
        state.items = updatedwoods;
        state.editStatus = "success";
        toast.info("wood Edited");
      },
      [woodsEdit.rejected]: (state, action) => {
        state.editStatus = "rejected";
      },
      [woodsDelete.pending]: (state, action) => {
        state.deleteStatus = "pending";
      },
      [woodsDelete.fulfilled]: (state, action) => {
        const newList = state.items.filter(
          (item) => item._id !== action.payload._id
        );
        state.items = newList;
        state.deleteStatus = "success";
        toast.error("wood Deleted"); // Fix typo here
      },      
      [woodsDelete.rejected]: (state, action) => {
        state.deleteStatus = "rejected";
      },
    },
  });

export default woodsSlice.reducer;